<template>
  <v-row class="fill-height">
    <v-col class="fill-height">
      <MapChart
        :countryData="{ US: 50, CA: 7, GB: 8, VE: 14, ES: 14  }"
        highColor="#4656ff"
        lowColor="#f4f778"
        countryStrokeColor="#c1c2cf"
        defaultCountryFillColor="#c1c2cf"
      />
    </v-col>
  </v-row>
</template>

<script>
import MapChart from "vue-map-chart";
export default {
  components: {
    MapChart,
  },
};
</script>

<style>
.vue-map-legend {
    background: #262737 !important;
    font-size: 0.8em;
    color: #c1c2cf;
    font-weight: bold;
}
.vue-map-legend-content {
     background: #c1c2cf !important;
     color: #262737;
     font-weight: bold;
}

</style>
