<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["data"],
  data() {
    return {
      chartData: {
        labels: [
          "STD",
          "DRY STD",
          "DD STD",
          "Reefer STD",
          "Flat Rack",
          "HC",
          "Dry HC",
          "DD HC",
          "Reefer HC",
        ],
        datasets: [
          {
            // label: 'Bar Chart',
            borderWidth: 1,

            pointBorderColor: "#2554FF",
            data: [800, 600, 300, 300, 100, 400, 600, 200],
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "#fff", // this here
                stepSize: 10,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
              ticks: {
                // display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      labels: [],
      infoData: [],
      backgroundColor: [],
      borderColor: [],
    };
  },
  mounted() {
    let count = 0;
    const backgroundColor = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
    ];
    const borderColor = [
      "rgba(255,99,132,1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];

    this.labels = this.data.map(value => {
      // console.log(count);
      let type = value.Type === null ? "Undefined" : value.Type;
      this.infoData.push(value.count);
      this.backgroundColor.push(backgroundColor[count]);
      this.borderColor.push(borderColor[count]);
      count = count === 5 ? 0 : count + 1;
      return type;
    });
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: "Amount",
            borderWidth: 1,
            pointBorderColor: "#2554FF",
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            data: this.infoData,
          },
        ],
      },
      this.options,
    );
  },
};
</script>
