<script>
import { HorizontalBar } from "vue-chartjs";
export default {
  extends: HorizontalBar,
  props: ["data"],

  mounted() {
    let data = this.data;

    this.renderChart(
      {
        labels: ["1-5 Days ", "6-15 Days ", "16+ Days "],
        datasets: [
          {
            borderWidth: 1,
            pointBorderColor: "#2554FF",
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
            ],
            pointBorderColor: "#2554FF",
            label: `Total=`,
            // data: data,
            data: [data[0], data[1], data[2]],
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "#fff", // this here
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "#fff", // this here
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    );
  },
};
</script>
