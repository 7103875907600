<template>
  <v-row>
    <v-col
      v-for="value of filterMenu"
      :key="value.title"
      class="pa-0 elevation-5"
    >
      <v-select
        :items="value.values"
        dense
        solo
        hide-details
        background-color="#262737"
        class="ma-0 rounded-0 pa-0 text-center"
      >
        <template v-slot:label>
          <span class="label-size">{{ value.title }}</span>
        </template>
        <template v-slot:item="{ item }">
          <span class="label-size item">{{ item }}</span>
        </template>
      </v-select></v-col
    >
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      filterMenu: [
        { title: "Type", values: ["1", "2", "3"] },
        { title: "Size", values: ["20", "40", "60"] },
        { title: "Condition Grade", values: ["20", "40", "60"] },
        { title: "Metro", values: ["20", "40", "60"] },
        { title: "Depot", values: ["20", "40", "60"] },
      ],
    };
  },
};
</script>

<style lang="scss">
.v-select-list {
  .v-list-item:hover {
    background: #4656ff !important;
  }
}
</style>
<style scoped>
.label-size {
  font-size: 0.8em;
  margin: 0px;
  padding: 0px;
}
.item {
  color: #c1c2cf;
  margin: 0;
}
.v-list {
}

.border-color {
  border: 1px #c1c2cf solid;
}
</style>
