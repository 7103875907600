<template>
  <v-dialog v-model="show" width="30%" class="modal">
    <v-card class="modal-card">
      <v-card-text class="pa-0">
        <v-card class="modal-card">
          <v-card-text class="pa-2 header-txt-location">
            <div class="text-center elevation-5 p-1">
              <v-list-item-title class="text-h5 white--text">
                {{ this.location.city }}, {{ this.location.state }}
              </v-list-item-title>
              <div class="white--text">
                {{ this.location.country }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-simple-table dense class="modal-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">TYPE</th>
                <th class="text-center">SIZE</th>
                <th class="text-center">QUANTITY</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in containersData" :key="index">
                <td class="text-center">{{ item.Type }}</td>
                <td class="text-center">{{ item.Size }}</td>
                <td class="text-center">{{ item.amount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <!-- <v-divider></v-divider> -->

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["info", "show", "location"],
  data() {
    return {
      containersData: [],
    };
  },
  watch: {
    info() {
      this.containersData = this.info;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.modal-card tbody tr {
  background: #262737 !important;
}
</style>

<style lang="scss">
.modal-card {
  background: #262737 !important;
  .modal-table {
    background-color: transparent !important;
    thead {
      background: #2196f3;
      border: solid 1px #212121 !important;
      -webkit-box-shadow: 0px 3px 5px -5px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
      -moz-box-shadow: 0px 3px 5px -5px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
      box-shadow: 0px 3px 15px -5px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
    }
    th {
      color: black !important;
    }
    td {
      border-bottom: solid #3284f4 1px !important;
      font-size: small !important;
    }
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
