<template>
  <v-card class="insight-card elevation-5 ma-0 fill-height d-flex" tile>
    <v-list-item class="w-100 pa-2">
      <v-list-item-content class="pa-0">
        <v-list-item-title
          class="headline mb-1 text-center text-size d-flex justify-center"
        >
          <slot name="content"></slot>
        </v-list-item-title>
        <v-list-item-subtitle class="text-end title-color">{{
          title
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.insight-card {
  // margin: 0 10%;

  // background: rgb(38, 39, 55);
// background: linear-gradient(41deg, rgba(38,39,55,1) 63%, rgba(55,81,120,1) 96%, rgba(50,132,244,1) 100%);
// background: linear-gradient(60deg, rgba(38,39,55,1) 83%, rgba(55,81,120,1) 100%, rgba(50,132,244,1) 100%);

// background: linear-gradient(90deg, rgba(55,81,120,1) 0%, rgba(38,39,55,1) 15%, rgba(38,39,55,1) 85%, rgba(55,81,120,1) 100%, rgba(50,132,244,1) 100%);

// background: linear-gradient(90deg, rgba(55,81,120,1) 0%, rgba(38,39,55,1) 10%, rgba(38,39,55,1) 90%, rgba(55,81,120,1) 100%, rgba(50,132,244,1) 100%);

  background-color: transparent;
}

.text-size {
  font-size: 2vw !important;
}

.title-color{
  color: #3284f4 !important;
  font-weight: bold;
  

}
</style>
