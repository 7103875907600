<template>
  <v-card class="statistics elevation-5 ma-0 dark-blue rounded" tile>
    <v-list-item three-line>
      <v-list-item-content class="pa-0 pt-3">
        <v-card-text>
          <div class="text">{{ data.dateTime }} - {{ data.message }}</div>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.statistics {
  margin: 0 10%;
  background-color: transparent;
}

.text {
  /* font-size: 0.8rem !important; */
  text-align: justify;
}
</style>
