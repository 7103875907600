<template>
  <v-list-item class="w-100 pa-2">
    <v-list-item-content class="pa-0">
      <v-list-item-subtitle class="text-start">{{
        title
      }}</v-list-item-subtitle>
      <v-list-item-title
        class="headline mb-1 text-center text-size d-flex justify-center"
      >
        {{ value }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ["title", "value"],
};
</script>

<style></style>
