<template>
  <div class="deck-container">
    <div id="map" ref="map"></div>
    <Modal
      :show="showModal"
      :info="containerDetails"
      :location="location"
      @closeModal="showModal = false"
    />
  </div>
</template>

<script>
import { MapboxOverlay as DeckOverlay } from "@deck.gl/mapbox";
import { IconLayer } from "@deck.gl/layers";
import mapboxgl from "mapbox-gl";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { heatmap_data } from "@/helpers/api/indexv2.js";
import Modal from "@/components/Insights/Modal.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      map: "",
      deck: "",
      accessToken:
        "pk.eyJ1Ijoicm9ud2VsIiwiYSI6ImNrdDI1YWJteDBnenUydW56NDNrMDduM28ifQ.O8wa3ArZrVY8ty8Fqb-pWg",
      INITIAL_VIEW_STATE: {
        longitude: -66.45756078877234,
        latitude: 9.157536917944968,
        zoom: 3,
        maxZoom: 16,
        pitch: 0,
        bearing: 0,
      },
      location: {},
      heatData: "",

      ICON_MAPPING: {
        marker: {
          x: 0,
          y: 0,
          width: 128,
          height: 128,
          anchorY: 138,
          mask: true,
        },
      },
      containerDetails: [],
    };
  },
  async mounted() {
    this.$store.commit("setSpinnerShow", true);
    try {
      const { data } = await heatmap_data();

      this.heatData = await this.array_locations(data);
    } catch (error) {
      console.log("ERROR: heatmap_data", error);
    }

    const map = new mapboxgl.Map({
      container: this.$refs.map,
      accessToken: this.accessToken,
      style: "mapbox://styles/mapbox/dark-v10",

      center: [
        this.heatData[0].coordinates[0],
        this.heatData[0].coordinates[1],
      ],
      zoom: this.INITIAL_VIEW_STATE.zoom,
      pitch: this.INITIAL_VIEW_STATE.pitch,
      bearing: this.INITIAL_VIEW_STATE.bearing,
    });

    const deckOverlay = new DeckOverlay({
      layers: [
        new HeatmapLayer({
          id: "heatmap",
          data: this.heatData,
          pickable: true,
          getPosition: (d) => d.coordinates,
          radiusPixels: 30,
          intensity: 1,
          threshold: 0.03,
          aggregation: "SUM",
          colorRange: [
            [239, 243, 255],
            [198, 219, 239],
            [158, 202, 225],
            [107, 174, 214],
            [49, 130, 189],
            [8, 81, 156],
          ],
        }),
        new IconLayer({
          id: "deck-canvas",
          data: this.heatData,
          pickable: true,
          iconAtlas:
            "https://res.cloudinary.com/yalibj/image/upload/c_thumb,h_128,w_128/v1619447954/v/maps-and-flags_blivpv.png",
          iconMapping: this.ICON_MAPPING,
          getIcon: () => "marker",
          sizeScale: 9,
          getPosition: (d) => d.coordinates,
          getSize: 5,
          getColor: [50, 132, 244],
        }),
      ],
      onClick: (layer) => {
        if (layer.picked && layer.object) {
          console.log(layer.object);
          this.containerDetails = layer.object.containerDetails;
          this.location = layer.object;
          this.showModal = true;
        }
      },
      getTooltip: (layer) => {
        if (layer.picked) {
          return (
            layer.object && {
              text: "fffdfd",
              html: `<h3>${layer.object.city ? layer.object.city : ""}, ${
                layer.object.state ? layer.object.state : ""
              },</h3><span>Containers Qty = ${layer.object.amount}</span>`,
              style: {
                borderRadius: "5px",
                border: "solid 1px #3284f4",
                backgroundColor: "#262737",
                fontSize: "0.9em",
              },
            }
          );
        }
      },
    });
    map.addControl(deckOverlay);
    map.addControl(new mapboxgl.NavigationControl());
    this.$store.commit("setSpinnerShow", false);
  },
  methods: {
    array_locations(data) {
      return new Promise((resolve, reject) => {
        let locations = [];
        data.map((value) => {
          if (parseInt(value.amount) !== 0) {
            locations.push({
              coordinates: [Number(value.longitude), Number(value.latitude)],
              amount: Number(value.amount),
              containerDetails: value.sizesAndTypes,

              city: value.City,
              country: value.Country,
              state: value.State,
            });
          }
          if (locations.length > 0) {
            this.INITIAL_VIEW_STATE.longitude = locations[0].coordinates[0];
            this.INITIAL_VIEW_STATE.latitude = locations[0].coordinates[1];
          }
        });
        resolve(locations);
      });
    },
  },
};
</script>

<style>
.deck-container {
  width: 100%;
  height: 100%;
  position: relative;
}
#map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
