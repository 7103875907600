<template>
  <v-select
    :items="options"
    append-icon="mdi-chevron-double-down"
    dense
    solo
    v-model="option"
    class="rounded-0"
    background-color="transparent"
    hide-details
    style="font-size:0.6em"
  >
  </v-select>
</template>

<script>
export default {
  data() {
    return {
      options: ["INVENTORY", "REQUESTS"],
      option: "INVENTORY",
    };
  },
};
</script>

<style></style>
